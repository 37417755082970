<template>
    <div class="memos-landing">
        <!-- :class="{ 'disabled-list' : isMemosListDisabled }" -->
        <!-- <auth-modal @closed-auth-modal="disableFetchingNewMemos" /> -->
        <mobile-download-banner class="d-block d-lg-none" />
        <nav-bar-auth v-if="!isLoggedIn" />
        <side-bar />
        <get-app class="d-none d-lg-block" />
        <div class="container">
            <div class="hero">
                <div class="logo">
                    <img src="@assets/img/founders-landing/memod-logo.svg" class="img-fluid" alt="logo Memod">
                </div>
                <h1>Powerful ideas made simple</h1>
                <h2>Share knowledge and inspire others with bite-sized Memos on any topic</h2>
                <h3>
                     <br>
                    🧠 Learn 5-10X faster | 💡 1,000s of topics | 💰 100% free
                </h3>
            </div>
        </div>
        <div class="memos-container container">
            <!-- <memod-list ref="memosListLanding" list-endpoint="/feeds?type=for-you" /> -->
            <notable />
        </div>
        <div class="container">
            <enjoying-memod />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
    name: "MemosLanding",
    components: {
        MobileDownloadBanner: () => import(/* webpackChunkName: "mobile-download-banner" */ "@c/molecules/mobile-download-banner"),
        GetApp: () => import(/* webpackChunkName: "get-app" */ "@c/molecules/get-the-app-qr"),
        SideBar: () => import(/* webpackChunkName: "side-bar" */ "@c/organisms/side-bar"),
        NavBarAuth: () => import(/* webpackChunkName: "nav-bar-auth" */ "@c/organisms/nav-bar-auth"),
        // MemodList: () => import(/* webpackChunkName: "memod-list" */ "@/components/organisms/memod-list.vue"),
        Notable: () => import(/* webpackChunkName: "notable" */ "@v/web-app/notable"),

        // AuthModal: () => import(/* webpackChunkName: "auth-modal" */ "@c/organisms/modals/auth/"),
        EnjoyingMemod: () => import(/* webpackChunkName: "enjoying-memod" */ "@c/molecules/enjoying-memod")
    },
    data() {
        return {
            alreadyOpenedSignUp: false,
            isMemosListDisabled: false
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        }),
        ...mapState({
            isMobileScreen: state => state.Application.isMobileScreen
        })
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
        this.handleSidebarTour();
    },
    mounted() {
        if (this.isLoggedIn) {
            this.$router.push({ name: "web-app-home" });
        }
        this.$emit("is-loading", false);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        handleScroll() {
            if (window.scrollY >= (window.innerHeight / 1.4) && !this.alreadyOpenedSignUp) {
                this.alreadyOpenedSignUp = true;
                window.removeEventListener("scroll", this.handleScroll);
                // Hide temporaly
                // this.$modal.show("auth-modal", { allowClose: true, showSignUp: true });
                this.disableFetchingNewMemos();
            }
        },
        handleSidebarTour() {
            const alreadyShowedSidebarTour = localStorage.getItem("alreadyShowedSidebarTour");
            if (!alreadyShowedSidebarTour && !this.isMobileScreen && this.isLoggedIn) {
                this.$store.dispatch("Application/toggleSideBar", true);
                this.$store.dispatch("Application/toggleSideBarTour", true);
            }
        },
        disableFetchingNewMemos() {
            this.isMemosListDisabled = true;
            this.$refs.memosListLanding.fetchingList = true;
        }
    }
}
</script>

<style lang="scss" scoped>
.memos-landing {
    padding-top: 135px;
    position: relative;
    padding-bottom: 70px;

    @media (min-width: 1200px) {
        .memos-container {
            max-width: 1440px;
        }
    }

    .hero {
        .logo {
            margin-bottom: 20px;
        }

        h1 {
            font-family: 'Zilla Slab', serif;
            font-style: italic;
            font-size: 56px;
            font-weight: 600;
            line-height: 60px;
            margin-bottom: 20px;
            order: 1;

            @media(max-width: $lg) {
                text-align: center;
            }

            @media(max-width: $md) {
                font-size: 38px;
                line-height: 40px;
            }
        }

        h3 {
            color: #B5B5B5;
            margin-bottom: 30px;
            order: 2;
            line-height: 1.5;
            font-size: 18px;
        }
    }

    &.disabled-list {
        /deep/ .loader {
            display: none;
        }
    }

    .enjoying-memod {
        margin-top: 30px;
    }
}
</style>
